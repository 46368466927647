import request from '@http';
// 获取综合管理列表
export function getContractBillLockStatusListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/contract/get/contract/bill/lock/status',
        params,
    });
}

// 综合管理导出
export function exportContractBillLockStatusListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/contract/get/contract/bill/lock/status/export',
        params,
        responseType: 'blob',
    });
}